<template>
  <span />
</template>

<script setup lang="ts">
import { LazyRegistrationQuickModal } from '#components'

const isQuickRegister = computed<boolean>(() => useAuthStore().isRegisterQuick && useAuthStore().isAuthenticated)

onMounted(() => {
  if (isQuickRegister.value) {
    const modal = openModal(
      LazyRegistrationQuickModal,
      {
        onClose() {
          modal.close()
        },
        onStartGuide() {
          modal.close()
        },
      },
      {
        escToClose: false,
        clickToClose: false,
        clickOutside: false,
      },
    )
  }
})
</script>
